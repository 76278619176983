<template>
  <TrSection label-id="tender-page-partial-orders">
    <template #head>
      <h2
        id="tender-page-partial-orders"
        class="text-lg leading-6 font-medium text-gray-900"
      >
        {{ $t('tender.page.partialOrders.head') }}
      </h2>
    </template>

    <div class="flex flex-col divide-y">
      <div
        v-for="(slot, index) in preparedSlots"
        :key="slot.id.toString()"
        :class="{
          'flex flex-col': true,
          'pb-3': slot.partialOrders.length > 0
        }"
      >
        <div
          :class="{
            'flex items-center  pb-4': true,
            'pt-6': index !== 0,
            'pt-1': index === 0
          }"
        >
          <div class="flex-1">
            <p class="text-sm font-medium text-primary-600">
              {{ slot.timeFrom.toHuman() }} - {{ slot.timeTo.toHuman() }}
            </p>
            <p class="text-sm text-gray-500">
              {{ slot.truckTypesText }}. {{ slot.truckBedTypesText }}<span
                v-if="slot.truckFeatures.length > 0"
              >. {{ slot.truckFeaturesText }}</span>
            </p>
          </div>
          <div
            class="flex-none flex items-center"
          >
            <Badge
              v-if="!slot.allowAccepting"
              :type="slot.isTaken ? BadgeType.NEUTRAL : BadgeType.SUCCESS"
            >
              {{ slot.isTaken ? $t('tender.slot.taken') : $t('tender.slot.free') }}
            </Badge>
            <RequiresAuth>
              <TrButton
                v-if="slot.allowAccepting"
                class="ml-2"
                :data-cy="`accept-slot-${slot.id}`"
                @click="acceptSlot(slot.id)"
              >
                {{ $t('tender.page.apply') }}
              </TrButton>
            </RequiresAuth>
          </div>
        </div>
        <TenderDetailsPartialOrder
          v-for="partialOrder in slot.partialOrders"
          :key="partialOrder.id.toString()"
          :tender-slot="slot"
          data-ct="tender-details-partial-order"
          :partial-order="partialOrder"
          :trucks-left="tender.trucksLeft"
          :tender="tender"
          @partial-order-rejected="$emit('partial-order-rejected', $event)"
          @partial-order-accepted="$emit('partial-order-accepted', $event)"
          @partial-order-revoked="$emit('partial-order-revoked', $event)"
          @partial-order-confirmed="$emit('partial-order-confirmed', $event)"
        />
      </div>
    </div>
  </TrSection>

  <SlotAcceptModal
    :tender-id="tender.id"
    :payment-scheme="tender.paymentScheme"
    :suggested-payment-amount="tender.paymentAmountSuggestion"
    :show="showSlotAcceptModal"
    :slot-id="slotAcceptModalSlotId"
    :estimate-costs="verifyAcceptSlotData?.estimatedCosts"
    @hide="$emit('update:slot-accept-modal-slot-id', undefined)"
    @dirty="$emit('dirty')"
  />
</template>

<script lang="ts" setup>
import TenderDetailsPartialOrder from '@app/tender/details/TenderDetailsPartialOrder.vue';
import TrSection from '@app/support/TrSection.vue';
import {Tender} from '@app/tender/Tender';
import {TenderPartialOrder, TenderPartialOrderStatus} from '@app/tender/TenderPartialOrder';
import TrButton from '@app/support/TrButton.vue';
import {computed, toRefs} from 'vue';
import SlotAcceptModal from '@app/tender/accept/SlotAcceptModal.vue';
import {Uuid} from '@app/uuid/Uuid';
import RequiresAuth from '@app/auth/RequiresAuth.vue';
import Badge from '@app/support/badge/Badge.vue';
import {BadgeType} from '@app/support/badge/BadgeType';
import {useI18n} from 'vue-i18n';
import {userCompanyId, userPermissions} from '@app/auth/Auth';
import {permissions} from '@app/auth/permissions/permissions';
import {VerifyCostActionInfo} from '@app/tender/VerifyCostActionInfo';
import {TenderStatus} from '@app/tender/TenderStatus';
import {naturalLanguageJoin} from '@app/i18n/naturalLanguageJoin';
import {useTruckTranslations} from '@app/i18n/useTruckTranslations';


interface Props {
  tender: Tender;
  isCreatorCompany: boolean;
  authCompanyId?: Uuid;
  verifyAcceptSlotData?: VerifyCostActionInfo;
  slotAcceptModalSlotId?: Uuid;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'partial-order-rejected', partialOrder: TenderPartialOrder): void,
  (e: 'partial-order-accepted', partialOrder: TenderPartialOrder): void,
  (e: 'partial-order-revoked', partialOrder: TenderPartialOrder): void,
  (e: 'partial-order-confirmed', partialOrder: TenderPartialOrder): void,
  (e: 'slot-accept', slotId: Uuid): void,
  (e: 'update:slot-accept-modal-slot-id', id: Props['slotAcceptModalSlotId']): void
  (e: 'dirty'): void
}>();

const {tender, isCreatorCompany, authCompanyId, slotAcceptModalSlotId} = toRefs(props);

const showSlotAcceptModal = computed(() => slotAcceptModalSlotId?.value !== undefined);

function acceptSlot(slotId: Uuid) {
  emit('slot-accept', slotId);
}

const {t} = useI18n();

const {
  truckTypeTranslation,
  truckBedTypeTranslation,
  truckFeaturesAnd,
} = useTruckTranslations();

/**
 * Prepare data of tender slots for display
 */
const preparedSlots = computed(() =>
    tender.value.slots.map((slot) => {
      /**
       * Partial orders by the company which are neither revoked nor rejected, because a company should not be able
       * to create multiple partial orders
       */
      const validOwnPartialOrders = slot.partialOrders.filter(partialOrder => {
        if (!partialOrder.contractor.id.equals(userCompanyId.value)) {
          return false;
        }

        return partialOrder.status !== TenderPartialOrderStatus.REVOKED && partialOrder.status !== TenderPartialOrderStatus.REJECTED;
      });
      const truckTypeTranslations = slot.truckTypes.map(truckTypeTranslation);
      const truckBedTypeTranslations = slot.truckBedTypes.map(truckBedTypeTranslation);

      return {
        ...slot,
        allowAccepting: tender.value.status === TenderStatus.OPEN && !slot.isTaken && userPermissions.value.has(permissions.APPLY_FOR_TENDER) && validOwnPartialOrders.length === 0,
        partialOrders: slot.partialOrders.filter(partialOrder => isCreatorCompany.value || partialOrder.contractor.id?.equals(authCompanyId?.value)),
        truckTypesText: naturalLanguageJoin(truckTypeTranslations, t('or')),
        truckBedTypesText: naturalLanguageJoin(truckBedTypeTranslations, t('or')),
        truckFeaturesText: truckFeaturesAnd(slot.truckFeatures),
      };
    }));
</script>
