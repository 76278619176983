<template>
  <div
    class="rounded-lg border border-l-[6px] border-l-gray-200 my-2"
    :class="borderClass"
  >
    <div class="flex-1 min-w-0 py-4 px-4">
      <div class="flex items-center justify-between">
        <p class="text-sm font-medium text-gray-900 truncate">
          {{ partialOrder.contractor.name }}
        </p>
        <PartialOrderActions
          :partial-order-id="partialOrder.id"
          :can-accept="canAccept"
          :can-reject="canReject"
          :can-revoke="canRevoke"
          :can-confirm="canConfirm"
          data-cy="partial-order-actions"
          @partial-order-accepted="$emit('partial-order-accepted', partialOrder)"
          @partial-order-rejected="$emit('partial-order-rejected', partialOrder)"
          @partial-order-revoked="$emit('partial-order-revoked', partialOrder)"
          @partial-order-confirmed="$emit('partial-order-confirmed', partialOrder)"
        />
      </div>

      <div class="mt-2 2xl:flex 2xl:justify-between 2xl:space-x-4">
        <div class="2xl:flex 2xl:space-x-4 flex-1">
          <div class="mt-2 flex text-sm text-gray-500 2xl:mt-0">
            <p v-if="partialOrder.message">
              "{{ partialOrder.message }}"
            </p>
            <p
              v-else
              class="italic"
            >
              {{ $t('tender.page.partialOrders.noMessage') }}
            </p>
          </div>
        </div>

        <div class="flex flex-col mt-4 2xl:mt-0">
          <div
            class="flex flex-col 2xl:flex-row items-start 2xl:items-center justify-end text-sm text-gray-500 2xl:mt-0 space-y-1 2xl:space-y-0 2xl:space-x-4 flex-none"
          >
            <div class="flex items-center">
              <UserIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>
                {{ partialOrder.createdBy.firstName }} {{ partialOrder.createdBy.lastName }}
              </p>
            </div>
            <div
              v-if="partialOrder.createdBy.phoneNumber"
              class="flex items-center"
            >
              <PhoneIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>
                {{ partialOrder.createdBy.phoneNumber }}
              </p>
            </div>
            <div
              v-if="partialOrder.createdBy.email"
              class="flex items-center"
            >
              <EnvelopeIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>
                {{ partialOrder.createdBy.email }}
              </p>
            </div>
          </div>

          <div
            class="pt-2 flex flex-col 2xl:flex-row 2xl:justify-end items-start 2xl:items-center text-sm text-gray-500 2xl:mt-0 space-y-1 2xl:space-y-0 2xl:space-x-4 flex-none"
          >
            <div class="flex items-center">
              <span
                class="inline-flex items-center gap-x-1.5 rounded-md"
              >
                <svg
                  :class="`h-1.5 w-1.5 mx-2 2xl:mx-0 ${badgeColor} ${partialOrder.status === TenderPartialOrderStatus.PENDING ? 'animate-pulse' : ''}`"
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="3"
                  />
                </svg>
                {{ $t('tender.page.partialOrders.' + partialOrder.status.toLowerCase()) }}
              </span>
            </div>
            <div class="mt-2 2xl:mt-0 flex items-center">
              <CurrencyEuroIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Money :value="partialOrder.paymentAmount" />
            </div>
            <div class="flex items-center">
              <CalendarIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>
                <time :datetime="partialOrder.createdAt.toISODate()">{{
                  partialOrder.createdAt.toHumanFull()
                }}
                </time>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {TenderPartialOrder, TenderPartialOrderStatus} from '@app/tender/TenderPartialOrder';
import {CalendarIcon, CurrencyEuroIcon, UserIcon, PhoneIcon, EnvelopeIcon} from '@heroicons/vue/20/solid';
import {userCompanyId} from '@app/auth/Auth';
import {computed, toRefs} from 'vue';
import {Tender} from '@app/tender/Tender';
import {TenderStatus} from '@app/tender/TenderStatus';
import {TenderSlot} from '@app/tender/TenderSlot';
import PartialOrderActions from '@app/tender/details/PartialOrderActions.vue';
import Money from '@app/money/money/Money.vue';


const props = defineProps<{
  partialOrder: TenderPartialOrder,
  tenderSlot: TenderSlot,
  tender: Tender
}>();

defineEmits<{
  (e: 'partial-order-rejected', partialOrder: TenderPartialOrder): void,
  (e: 'partial-order-accepted', partialOrder: TenderPartialOrder): void,
  (e: 'partial-order-revoked', partialOrder: TenderPartialOrder): void,
  (e: 'partial-order-confirmed', partialOrder: TenderPartialOrder): void,
}>();

const {
  partialOrder,
  tender,
  tenderSlot,
} = toRefs(props);

const tenderCanceled = computed(() => tender.value.status === TenderStatus.CANCELED);
const tenderDone = computed(() => tender.value.status === TenderStatus.DONE);

const isTenderCreatorCompany = computed(() => {
  if (!tender.value?.company.id) {
    return false;
  }

  return userCompanyId.value?.equals(tender.value.company.id) ?? false;
});

const isPartialOrderCreatorCompany = computed(() => {
  if (!partialOrder.value?.contractor.id) {
    return false;
  }

  return userCompanyId.value?.equals(partialOrder.value.contractor.id) ?? false;
});

const isStatusChangeable = computed(() => !tenderCanceled.value && !tenderDone.value);

// TODO rechte
const canAccept = computed(() => {
  return isTenderCreatorCompany.value
      && isStatusChangeable.value
      && !tenderSlot.value.isTaken
      && TenderPartialOrderStatus.REVOKED !== partialOrder.value.status
      && TenderPartialOrderStatus.ACCEPTED !== partialOrder.value.status
      && TenderPartialOrderStatus.OUTDATED !== partialOrder.value.status;
});

// TODO rechte
const canReject = computed(() => {
  return isTenderCreatorCompany.value
      && isStatusChangeable.value
      && TenderPartialOrderStatus.REVOKED !== partialOrder.value.status
      && TenderPartialOrderStatus.REJECTED !== partialOrder.value.status
      && TenderPartialOrderStatus.OUTDATED !== partialOrder.value.status;
});

// TODO rechte
const canRevoke = computed(() => {
  return isPartialOrderCreatorCompany.value
      && isStatusChangeable.value
      && TenderPartialOrderStatus.REVOKED !== partialOrder.value.status
      && TenderPartialOrderStatus.REJECTED !== partialOrder.value.status;
});

// TODO rechte
const canConfirm = computed(() => {
  return isPartialOrderCreatorCompany.value
      && isStatusChangeable.value
      && TenderPartialOrderStatus.OUTDATED === partialOrder.value.status;
});

const borderClass = computed(() => {
  return ({
    [TenderPartialOrderStatus.PENDING]: 'border-l-yellow-300',
    [TenderPartialOrderStatus.ACCEPTED]: 'border-l-green-300',
    [TenderPartialOrderStatus.REVOKED]: 'border-l-red-300',
    [TenderPartialOrderStatus.REJECTED]: 'border-l-red-300',
    [TenderPartialOrderStatus.OUTDATED]: 'border-l-gray-300',
  })[partialOrder.value.status];
});

const badgeColor = computed(() => {
  return {
    [TenderPartialOrderStatus.PENDING]: 'fill-yellow-500',
    [TenderPartialOrderStatus.ACCEPTED]: 'fill-green-500',
    [TenderPartialOrderStatus.REVOKED]: 'fill-red-500',
    [TenderPartialOrderStatus.REJECTED]: 'fill-red-500',
    [TenderPartialOrderStatus.OUTDATED]: 'fill-gray-500',
  }[partialOrder.value.status];
});
</script>
