<template>
  <div v-if="!loading && user">
    <Back class="mb-4" />

    <div class="">
      <div class="flex">
        <h1 class="truncate text-2xl font-bold text-gray-900 flex-1">
          {{ fullName }}
        </h1>

        <template v-if="userPermissions.has(permissions.UPDATE_USER)">
          <ButtonWithDropdown
            v-if="!isCurrentUser"
            class="flex-none"
            data-cy="user-edit-button"
            @click-main="goToUpdateRoute"
          >
            {{ $t('company.userDetails.update') }}
            <template #items>
              <div class="py-1">
                <MenuItem
                  v-slot="{ active }"
                  data-cy="user-details-edit"
                >
                  <button
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'w-full group flex items-center px-4 py-2 text-sm']"
                    @click="toggleUserActive"
                  >
                    {{
                      user.active ? $t('company.userDetails.actions.deactivate') : $t('company.userDetails.actions.activate')
                    }}
                  </button>
                </MenuItem>
              </div>
            </template>
          </ButtonWithDropdown>

          <TrButton
            v-else
            :color-scheme="ButtonColorScheme.TERTIARY"
            class="flex-none"
            data-cy="user-edit-button"
            @click="goToUpdateRoute"
          >
            {{ $t('company.userDetails.update') }}
          </TrButton>
        </template>
      </div>

      <div
        v-if="!user.active"
        class="mt-8"
      >
        <Alert
          :title="$t('company.userDetails.inactive.title')"
        >
          <template #sub>
            {{ $t('company.userDetails.inactive.sub') }}
          </template>
        </Alert>
      </div>

      <TrSection
        label-id="user-details-headline"
        class="mt-6 min-w-0 flex-1"
      >
        <template #head>
          <SectionHeading
            id="user-details-headline"
          >
            {{ $t('company.userDetails.head') }}
          </SectionHeading>
          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('company.userDetails.sub') }}
          </p>
        </template>
        <div class="flex items-center">
          <span class="flex-none mr-2">
            <EnvelopeIcon class="w-4 h-4 text-gray-600" />
          </span>
          <p>{{ user.email }}</p>
        </div>
        <div class="flex items-center pt-1">
          <span class="flex-none mr-2">
            <PhoneIcon class="w-4 h-4 text-gray-600" />
          </span>
          <p>{{ user.phoneNumber }}</p>
        </div>

        <Tabs
          :tabs="tabs"
          :current-tab-id="currentTabId"
          @change="activateTab($event)"
        >
          <div
            v-if="currentTabId === 'permissions'"
            class="mt-8 flex flex-wrap"
          >
            <div
              v-for="permission in hydratedPermissions"
              :key="permission.id"
              class="px-4 py-1 w-1/2"
            >
              <Checkbox
                :id="`permissions-checkbox-${permission.id}`"
                data-cy="permissions-checkbox"
                disabled
                :checked="permission.isGranted"
              >
                {{ $t(`auth.permission.${permission.id}`) }}
              </checkbox>
            </div>
          </div>
        </Tabs>
      </TrSection>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ComputedRef, ref} from 'vue';
import companyService from '@app/company/CompanyService';
import {Uuid} from '@app/uuid/Uuid';
import {CompanyUser} from '@app/company/CompanyUser';
import {useRoute, useRouter} from 'vue-router';
import {Tab} from '@app/navigation/Tab';
import {useI18n} from 'vue-i18n';
import {permissions} from '@app/auth/permissions/permissions';
import auth, {userId, userPermissions} from '@app/auth/Auth';
import Checkbox from '@app/forms/checkbox/Checkbox.vue';
import PhoneIcon from '@heroicons/vue/24/outline/PhoneIcon';
import EnvelopeIcon from '@heroicons/vue/24/outline/EnvelopeIcon';
import Back from '@app/routing/Back.vue';
import ButtonWithDropdown from '@app/buttons/ButtonWithDropdown.vue';
import {MenuItem} from '@headlessui/vue';
import {updateUserRoute} from '@app/company/routes';
import Alert from '@app/feedback/Alert.vue';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import TrButton from '@app/support/TrButton.vue';
import Tabs from '@app/navigation/Tabs.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {useUserForm} from '@app/company/useUserForm';

const user = ref<CompanyUser>();

const {t} = useI18n();

const router = useRouter();
const route = useRoute();
const userIdParam = computed(() => Uuid.fromString(route.params.userId as string));

const {
  grantedPermissions,
  hydratedPermissions,
} = useUserForm(userIdParam);

const fullName: ComputedRef<undefined | string> = computed(() => {
  if (!user.value) {
    return undefined;
  }
  return user.value?.firstName + ' ' + user.value?.lastName;
});

const hasGrantPermissionPermission = computed(() => userPermissions.value.has(permissions.GRANT_PERMISSION));
const tabs: ComputedRef<Tab[]> = computed(() => hasGrantPermissionPermission.value ? [
    {
        name: t('company.userDetails.permissions'),
        id: 'permissions',
    },
] : []);

const getTabFromRoute = () => {
  if (tabs.value.length === 0) {
    return undefined;
  }

  let routeTab = route.params.tab;

  if (!routeTab || Array.isArray(routeTab) || !isValidTabId(routeTab)) {
    routeTab = tabs.value[0].id;
  }
  return routeTab;
};

const currentTabId = ref<string | undefined>(getTabFromRoute());

const activateTab = (tab: Tab) => {
  currentTabId.value = tab.id;
  router.push({params: {tab: tab.id}});
};

const isValidTabId = (id: string): boolean => tabs.value.some((tab: Tab) => tab.id === id);


const {execute: loadUser, isFetching: loading} = useAsyncAction(async () => {
  const {data} = await companyService.getUserDetails(userIdParam.value);
  user.value = data;

  grantedPermissions.value = [...data.permissions];
});
useAppShellBarLoader(loading);

loadUser();

const {execute: toggleUserActive} = useAsyncAction(async function () {
  if (!user.value) {
    return;
  }

  if (user.value?.active) {
    await companyService.deactivateUser(user.value.id, auth.getCompanyId());
  } else {
    await companyService.activateUser(user.value.id, auth.getCompanyId());
  }
  await loadUser();
});

function goToUpdateRoute() {
  router.push({name: updateUserRoute.name});
}

const isCurrentUser = computed(() => user.value?.id.equals(userId.value));
</script>
