<template>
  <div
    v-if="!isPending && tender"
    class="pb-5"
  >
    <div
      class="lg:flex lg:items-center lg:justify-between lg:space-x-5"
    >
      <div class="flex items-center space-x-5">
        <h1
          class="text-2xl font-bold text-gray-900"
          v-text="tender?.title"
        />
      </div>
      <div
        :class="{
          'flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0': true,
          'mt-6 lg:mt-0': isEditable || isDuplicatable || isCancelable
        }"
      >
        <RequiresAuth>
          <TrButton
            v-if="isCancelable"
            :color-scheme="ButtonColorScheme.TERTIARY"
            @click="showTenderCancelModal = true"
          >
            {{ $t('tender.page.cancel') }}
          </TrButton>
        </RequiresAuth>

        <RequiresAuth>
          <TrButton
            v-if="isEditable"
            :color-scheme="ButtonColorScheme.TERTIARY"
            data-cy="edit-tender"
            @click="updateTender"
          >
            {{ $t('tender.page.edit') }}
          </TrButton>
        </RequiresAuth>

        <RequiresAuth>
          <TrButton
            v-if="isDuplicatable"
            :color-scheme="ButtonColorScheme.TERTIARY"
            data-cy="duplicate-tender"
            @click="duplicateTender"
          >
            {{ $t('tender.page.duplicate') }}
          </TrButton>
        </RequiresAuth>
      </div>
    </div>

    <div
      v-if="tender?.status === TenderStatus.CANCELED"
      class="mt-8"
    >
      <Alert :title="$t('tender.page.canceledAlert.title')">
        <template #sub>
          {{ $t('tender.page.canceledAlert.sub') }}
        </template>
      </Alert>
    </div>

    <div
      v-if="tender?.status === TenderStatus.DONE"
      class="mt-8"
      data-cy="tender-done-warning"
    >
      <Alert :title="$t('tender.page.doneAlert.title')">
        <template #sub>
          {{ $t('tender.page.doneAlert.sub') }}
        </template>
      </Alert>
    </div>

    <div class="flex flex-col-reverse lg:flex-row items-start">
      <div class="w-full lg:w-4/5 mr-8">
        <TenderDetailsInfoSection
          :tender="tender"
        />

        <TenderDetailsLocationSection
          v-if="mapProviderEnv === 'GOOGLE'"
          :tender="tender"
          :current-location-lat="currentLocationLat"
          :current-location-lng="currentLocationLng"
        />

        <TenderDetailsSlotsSection
          v-model:slot-accept-modal-slot-id="slotAcceptModalSlotId"
          :tender="tender"
          :is-creator-company="isCreatorCompany"
          :auth-company-id="userCompanyId"
          :verify-accept-slot-data="verifyAcceptSlotData"
          @partial-order-rejected="rejectPartialOrder"
          @partial-order-accepted="acceptPartialOrder"
          @partial-order-revoked="revokePartialOrder"
          @partial-order-confirmed="confirmPartialOrder"
          @slot-accept="acceptSlot"
          @dirty="fetchTenderDetails"
        />

        <TenderDetailsContactSection
          :tender="tender"
        />
      </div>

      <TenderDetailsMetaData
        class="w-full lg:w-1/5 mt-8"
        :tender="tender"
      />
    </div>
  </div>

  <NoData v-else-if="tenderNotFound">
    {{ $t('tender.page.tenderNotFound') }}
  </NoData>

  <TenderCancelModal
    :show="showTenderCancelModal"
    :loading="cancelFetching"
    @hide="showTenderCancelModal = false"
    @cancel="cancelTender"
  />

  <AcceptPartialOrderInfoModal
    v-if="tender"
    :title="acceptInfoModalTitel"
    :show="!!currentPartialOrderToAccept"
    :estimated-costs="verifyAcceptPartialOrderData?.estimatedCosts"
    :is-loading="acceptPartialOrderLoading"
    @hide="currentPartialOrderToAccept = undefined"
    @accept="onModalAccept"
  />

  <BillingAddressRequiredModal
    v-if="hasAuthToken"
    :show="!!billingAddressRequiredPartialOrderId"
    :create-link="{
      name: updateCompanyRoute.name,
      params: {companyId: auth.getCompanyId().toString()},
      query: {acceptPartialOrder: billingAddressRequiredPartialOrderId?.toString(), tender: tenderId}
    }"
    @hide="billingAddressRequiredPartialOrderId = undefined"
  />

  <BillingAddressRequiredModal
    v-if="hasAuthToken"
    :show="!!billingAddressRequiredSlotId"
    :create-link="{
      name: updateCompanyRoute.name,
      params: {companyId: auth.getCompanyId().toString()},
      query: {createPartialOrder: billingAddressRequiredSlotId?.toString(), tender: tenderId}
    }"
    @hide="billingAddressRequiredSlotId = undefined"
  />
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import tenderService from '@app/tender/TenderService';
import {useRoute, useRouter} from 'vue-router';
import {Tender} from '@app/tender/Tender';
import TrButton from '@app/support/TrButton.vue';
import RequiresAuth from '@app/auth/RequiresAuth.vue';
import TenderDetailsInfoSection from '@app/tender/details/TenderDetailsInfoSection.vue';
import TenderDetailsContactSection from '@app/tender/details/TenderDetailsContactSection.vue';
import TenderDetailsSlotsSection from '@app/tender/details/TenderDetailsSlotsSection.vue';
import auth, {hasAuthToken, userCompanyId, userId, userPermissions} from '@app/auth/Auth';
import TenderCancelModal from '@app/tender/cancel/TenderCancelModal.vue';
import {TenderStatus} from '@app/tender/TenderStatus';
import {TenderPartialOrder} from '@app/tender/TenderPartialOrder';
import {Uuid} from '@app/uuid/Uuid';
import {tenderCancelService} from '@app/tender/cancel/TenderCancelService';
import Alert from '@app/feedback/Alert.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {tenderRoles} from '@app/tender/TenderRole';
import {permissions} from '@app/auth/permissions/permissions';
import {duplicateTenderRoute, updateTenderRoute} from '@app/tender/routes';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import NoData from '@app/support/NoData.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import AcceptPartialOrderInfoModal from '@app/tender/details/AcceptPartialOrderInfoModal.vue';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {useSse} from '@app/sse/useSse';
import {SseMessage} from '@app/sse/SseMessage';
import {useI18n} from 'vue-i18n';
import {useToast} from '@app/overlays/toast/useToast';
import BillingAddressRequiredModal from '@app/billing/BillingAddressRequiredModal.vue';
import {updateCompanyRoute} from '@app/company/routes';
import {urlParamRef} from '@app/routing/urlParamRef';
import TenderDetailsLocationSection from '@app/tender/details/TenderDetailsLocationSection.vue';
import {MapProvider} from '@app/location/MapProvider';
import {TenderContactPerson} from '@app/tender/TenderContactPerson';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import TenderDetailsMetaData from '@app/tender/details/TenderDetailsMetaData.vue';


const mapProviderEnv: MapProvider | undefined = import.meta.env.VITE_APP_MAP_PROVIDER;

const router = useRouter();
const route = useRoute();
const {t} = useI18n();

const acceptPartialOrderParam = urlParamRef('acceptPartialOrder');
const acceptInfoModalTitel = ref(t('tender.page.costInfo.head'));

const tender = ref<Tender>();

const showTenderCancelModal = ref(false);
const tenderNotFound = ref(false);

const isCreatorCompany = computed(() => {
  if (!tender.value?.company.id) {
    return false;
  }

  return userCompanyId.value?.equals(tender.value.company.id) ?? false;
});

const tenderId = computed(() => route.params.id as string);
const currentLocationLatRaw = urlParamRef('lat');
const currentLocationLngRaw = urlParamRef('lng');
const currentLocationLat = computed(() => currentLocationLatRaw.value ? Number.parseFloat(currentLocationLatRaw.value + '') : undefined);
const currentLocationLng = computed(() => currentLocationLngRaw.value ? Number.parseFloat(currentLocationLngRaw.value + '') : undefined);

/**
 * We might be coming from billing address update page which will append an url param, so we can continue accepting po
 */
async function acceptPartialOrderFormUrlParam(data: Tender) {
  const partialOrderIdToAccept = acceptPartialOrderParam.value;
  if (!partialOrderIdToAccept) {
    return;
  }

  const partialOrder = data.slots.flatMap((slot) => slot.partialOrders)
      .find((partialOrder) => partialOrder.id.equals(Uuid.fromString(partialOrderIdToAccept)));

  if (!partialOrder) {
    return;
  }

  acceptInfoModalTitel.value = t('tender.page.costInfo.poHead', {companyName: partialOrder.contractor.name});
  acceptPartialOrderParam.value = undefined;
  await acceptPartialOrder(partialOrder);
}

/**
 * We might be coming from billing address update page which will append an url param, so we can continue creating po
 */
async function createPartialOrderFormUrlParam(data: Tender) {
  const slotIdToAccept = createPartialOrderParam.value;
  if (!slotIdToAccept) {
    return;
  }

  const slot = data.slots.find((slot) => slot.id.equals(Uuid.fromString(slotIdToAccept)));

  if (!slot) {
    throw 'Invalid slot id given to create partial order';
  }

  createPartialOrderParam.value = undefined;
  await acceptSlot(slot.id);
}

const {execute: fetchTenderDetails, isPending} = useAsyncAction(async function () {
  const id = Uuid.fromString(tenderId.value);
  const {data} = await tenderService.fetchTenderDetails(id);
  tender.value = data;
  await acceptPartialOrderFormUrlParam(data);
  await createPartialOrderFormUrlParam(data);
}, (errorHandler) => errorHandler.onValidationError(ValidationErrorCode.TENDER_NOT_FOUND, () => tenderNotFound.value = true));

useAppShellBarLoader(isPending);

// TODO loading state
const {execute: rejectPartialOrder} = useAsyncAction(async (partialOrder: TenderPartialOrder) => {
  if (!tender.value) {
    return;
  }

  await tenderService.rejectPartialOrder(tender.value.id as Uuid, partialOrder.id);

  await fetchTenderDetails();
});

const currentPartialOrderToAccept = ref<Uuid>();
const billingAddressRequiredPartialOrderId = ref<Uuid>();
const {
  data: verifyAcceptPartialOrderData,
  execute: acceptPartialOrder,
} = useAsyncAction(async (partialOrder: TenderPartialOrder) => {
  if (!tender.value) {
    return;
  }

  const verify = await tenderService.verifyAcceptPartialOrder(tender.value.id, partialOrder.id);

  if (verify.estimatedCosts.toNumber() === 0) {
    // no need to show verify modal as there'll be no costs
    await doAcceptPartialOrder(tender.value?.id, partialOrder.id);
    return;
  }

  if (!verify.hasAccountingInfo) {
    billingAddressRequiredPartialOrderId.value = partialOrder.id;
    return;
  }

  currentPartialOrderToAccept.value = partialOrder.id;
  return verify;
});

const {
  execute: doAcceptPartialOrder,
  isFetching: acceptPartialOrderLoading,
} = useAsyncAction(async (tenderId: Uuid, partialOrderId: Uuid) => {
  if (!tender.value) {
    return;
  }

  await tenderService.acceptPartialOrder(tenderId, partialOrderId);
  await fetchTenderDetails();

  currentPartialOrderToAccept.value = undefined;
});

// TODO loading state
const {execute: revokePartialOrder} = useAsyncAction(async (partialOrder: TenderPartialOrder) => {
  if (!tender.value) {
    return;
  }
  await tenderService.revokePartialOrder(tender.value.id, partialOrder.id);

  await fetchTenderDetails();
});

// TODO loading state
const {execute: confirmPartialOrder} = useAsyncAction(async (partialOrder: TenderPartialOrder) => {
  if (!tender.value) {
    return;
  }
  await tenderService.confirmPartialOrder(tender.value.id, partialOrder.id);

  await fetchTenderDetails();
});

const {execute: cancelTender, isFetching: cancelFetching} = useAsyncAction(async () => {
  await tenderCancelService.cancel(tender.value!.id);
  await fetchTenderDetails();
  showTenderCancelModal.value = false;
});

const satisfiesEditPermissions = () => {
  if (!hasAuthToken.value) {
    return false;
  }

  if (!isCreatorCompany.value) {
    return false;
  }

  return isPrivilegedInTender()
      || userPermissions.value.has(permissions.MODIFY_TENDER);
};

const satisfiesCancelPermissions = () => {
  if (!hasAuthToken.value) {
    return false;
  }

  if (!isCreatorCompany.value) {
    return false;
  }

  return isPrivilegedInTender()
      || userPermissions.value.has(permissions.MODIFY_TENDER);
};

const isEditable = computed(() =>
    tender.value?.status
    && satisfiesEditPermissions()
    && [TenderStatus.OPEN, TenderStatus.FULL].includes(tender.value?.status),
);

const isCancelable = computed(() =>
    tender.value?.status
    && satisfiesCancelPermissions()
    && [TenderStatus.OPEN, TenderStatus.FULL].includes(tender.value?.status),
);

const isDuplicatable = computed(() =>
    tender.value?.status
    && isCreatorCompany.value
    && userPermissions.value.has(permissions.CREATE_TENDER),
);

const isDispatcherOrConstructionManager = (tenderParticipant: TenderContactPerson) => tenderParticipant.role === tenderRoles.CONSTRUCTION_MANAGER || tenderParticipant.role === tenderRoles.DISPATCHER;

const isPrivilegedInTender = () => {
  if (!tender.value) {
    return false;
  }

  if (tender.value.createdByCurrentUser) {
    return true;
  }

  return tender.value.participants.filter(tenderParticipant => tenderParticipant.id.equals(userId.value))
      .some(isDispatcherOrConstructionManager);
};

function updateTender() {
  router.push({name: updateTenderRoute.name, params: {id: tender.value?.id.toString()}});
}

function duplicateTender() {
  router.push({name: duplicateTenderRoute.name, params: {id: tender.value?.id.toString()}});
}

fetchTenderDetails();

interface TenderUpdatedMessage extends SseMessage {
  name: 'TENDER_UPDATED'
  data: ''
}

const {show: showReloadToast} = useToast({
  mode: ToastMode.INFO,
  title: t('tender.update.updatedToast.title'),
  body: t('tender.update.updatedToast.body'),
  ctaAction: fetchTenderDetails,
  ctaText: t('tender.update.updatedToast.cta'),
  duration: Infinity,
  id: 'reload-tender-details',
}, true, true);

async function subscribeToUpdates() {
  if (!hasAuthToken.value) {
    return;
  }

  const {connection} = useSse<TenderUpdatedMessage>(`/secure/sse/tender/${tenderId.value}`);

  connection.onEventName('TENDER_UPDATED', showReloadToast);
}

subscribeToUpdates();

function onModalAccept() {
  if (!currentPartialOrderToAccept.value) {
    throw 'No currentPartialOrderToAccept set while trying to accept modal';
  }
  if (!tender.value) {
    throw 'No tender set while trying to accept modal';
  }
  doAcceptPartialOrder(tender.value.id, currentPartialOrderToAccept.value);
}

/** id of slot to accept, i.e. create a partial order for */
const slotAcceptModalSlotId = ref<Uuid>();
/** id of slot which was requested to accept but billing address is needed */
const billingAddressRequiredSlotId = ref<Uuid>();
/** param that leads to slot accept modal to open on page load, for example when coming from updateCompany page */
const createPartialOrderParam = urlParamRef('createPartialOrder');
const {execute: acceptSlot, data: verifyAcceptSlotData} = useAsyncAction(async function (slotId: Uuid) {
  if (!tender.value) {
    throw 'Tender undefined when accepting slot. Should not happen.';
  }

  const data = await tenderService.verifyAcceptSlot(
      tender.value.id,
      slotId,
      auth.getCompanyId(),
  );

  if (!data.hasAccountingInfo) {
    billingAddressRequiredSlotId.value = slotId;
    return;
  }

  slotAcceptModalSlotId.value = slotId;
  return data;
});
</script>
