import {computed, Ref, ref} from 'vue';
import type {Permission} from '@app/auth/permissions/Permission';
import {permissions} from '@app/auth/permissions/permissions';
import {Uuid} from '@app/uuid/Uuid';
import {userId} from '@app/auth/Auth';

export function useUserForm(formUserId: Ref<Uuid>) {
    const grantedPermissions = ref<Permission[]>();

    return {
        grantedPermissions: grantedPermissions,
        hydratedPermissions: computed(() =>
            Object.values(permissions)
                // SYSTEM_READ_REGISTERED_COMPANIES is an internal permission which should not be seen on the UI
                .filter((permission) => permission !== permissions.SYSTEM_READ_REGISTERED_COMPANIES)
                .map(permission => ({
                    id: permission,
                    isGranted: grantedPermissions.value?.includes(permission),
                    // User should not be able to revoke own grant_permission or update_user permissions as that would make their account/company useless if there is no one else with those permissions
                    allowEdit: !(formUserId.value?.equals(userId.value) && (permission === permissions.GRANT_PERMISSION || permission === permissions.UPDATE_USER)),
                })),
        ),
    };
}
