<template>
  <div class="flex break-all items-stretch py-4 px-4 my-2">
    <span class="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100 flex-none">
      <UserImg class="h-full w-full text-gray-300" />
    </span>

    <div class="flex flex-col lg:flex-row flex-1 ml-4 lg:items-center">
      <div class="flex-1">
        <p
          class="text-gray-900 font-medium"
          v-text="fullName"
        />

        <p class="font-extralight">
          {{ $t(`truckOffer.role.${participant.role}`) }}
        </p>
      </div>
      <div
        v-if="participant.phoneNumber || participant.email"
        class="flex-1 font-extralight mt-2 lg:mt-0"
      >
        <div
          v-if="participant.phoneNumber"
          class="flex items-center"
        >
          <PhoneIcon class="flex-none mr-1 w-4 h-4 text-gray-600" />
          <span class="flex-1">{{ participant.phoneNumber }}</span>
        </div>
        <div
          v-if="participant.email"
          class="flex items-center"
        >
          <EnvelopeIcon class="flex-none mr-1 w-4 h-4 text-gray-600" />
          <span class="flex-1">{{ participant.email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, toRefs} from 'vue';
import UserImg from '@app/company/UserImg.vue';
import PhoneIcon from '@heroicons/vue/24/outline/PhoneIcon';
import EnvelopeIcon from '@heroicons/vue/24/outline/EnvelopeIcon';
import {TruckOfferContactPerson} from '@app/truckoffer/TruckOfferContactPerson';

const props = defineProps<{
  participant: TruckOfferContactPerson
}>();

const {participant} = toRefs(props);

const fullName = computed(() => `${participant.value.firstName} ${participant.value.lastName}`);
</script>
